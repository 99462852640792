@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import 'variables.scss';

.error-row {
    background-color: lightcoral !important;
}
.success-row {
    background-color: lightgreen !important ;
}

.exists-row {
    background-color: lightblue  !important ;
}

.duplicate-row {
    background-color: rgb(59, 204, 33)  !important ;
}

// Overwrite styles

.ag-theme-alpine .ag-header {
    background-color: $logo-main;
}

.ag-header-group-cell-label, .ag-header-cell-label,
.ag-theme-alpine .ag-icon-filter:before,
.ag-theme-alpine .ag-header-icon,
.ag-theme-alpine .ag-icon
 {
    color: $white;
}



.ag-theme-alpine .ag-header-cell-resize::after {
    background-color: $white;
}

.ag-theme-alpine{
   
    --ag-borders-critical:solid 1px;
    --ag-border-color:white;
}
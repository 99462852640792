@import 'variables';
@import 'mixins';

// 
// INCLUDES STYLES FOR TABLES, TABLE CONTROLS, TABLE RELATED STUFF
// INCLUDING THE TABLE TOOLBAR
// 

.table-control {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    color: $text-color;
    padding: 8px;

    mat-icon {
        width: 30px;
        height: 30px;
        vertical-align: top;
    }

    &.disabled {
        opacity: 0.25;
        pointer-events: none;
        cursor: default;
    }
}

.table-control-with-input {
    padding-top: 8px;

    label {
        display: inline-block;
        margin: 0 8px;
        vertical-align: super;
    }

    input {
        border: 1px solid #DDDDDD;
        width: 140px;
        padding: 4px;
    }
}

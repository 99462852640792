@import 'mixins';
@import 'variables';

// 
// INCLUDES STYLES FOR FORMS, INPUTS, SELECTS, RADIOS, CHECKBOXES, BUTTONS
//
/**
 * Forms
 */
 .simple-form {
    .form-control {
        margin-bottom: 12px;
    }

    label {
        display: block;
        margin-bottom: 4px;

        &.required {
            &::after {
                content: '*';
                color: $error;
                margin-left: 4px;
            }
        }
    }

    input,
    textarea {
        display: block;
        resize: none;
        width: 100%;
        padding: 4px;
    }
}

.dual-input {
    display: flex;
    margin-bottom: 16px;
    
    input {
        border: 1px solid #949494;
        border-radius: 0;
    }

    input:first-of-type {
        flex: 0.45;
        width: 45%;
        border-right:none;
    }

    input:last-of-type {
        flex: 0.45;
        width: 45%;
    }

    mat-icon {
        cursor: pointer;
        flex: 0.2;
        height: 30px;
    }
}

.validation-error {
    color: $error;
    position: relative;
    top: -18px;
    font-size: 12px;
}

/**
 * Buttons
 */
 button {
    font-family: inherit;
}

 button[disabled] {
    pointer-events: none;
    opacity: 0.5;

    mat-icon {
        opacity: 0.4;
        pointer-events: none;
    }
}
 .button {
    background-color: $text-menu;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    color: $text-default;
    padding: 10px 16px;
    transition: all .4s ease;
    font-family: $ff-bold;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2), 0 8px 6px -6px rgba(0, 0, 0, 0.19);

    &-no-shadow {
        box-shadow: none;
    }

    &-medium {
        font-size: 16px;
    }

    &-inline {
        color: $logo-dark;
        text-decoration: underline;
        background: transparent;
        box-shadow: none;

        span.icon {
            vertical-align: top;
            margin-right: 4px;
            margin-top: 3px;
        }
    }

    &-gray {
        border: 1px solid $text-default;
        background-color: $medium-gray;
        color: $white;
    }

    &-white {
        background-color: $white;
        color: $logo-main;
        border: 1px solid $white;

        &:hover {
            color: $white;
            background-color: transparent;
        }
    }

    &-blue {
        border: 1px solid $logo-main;
        background-color: $logo-main;
        color: $white;

        &:hover {
            color: $logo-main;
            background-color: $white;
        }
    }
    &-red {
        border: 1px solid $logo-main;
        background-color: red;
        color: $white;

        &:hover {
            color: $logo-main;
            background-color: $white;
        }
    }

    &-outlined {
        border: 1px solid $logo-main;
        background-color: transparent;
        color: $logo-main;

        &:hover {
            color: $white;
            background-color: $logo-main;
        }
    }

    &-outlined-white {
        border: 1px solid $white;
        background-color: transparent;
        color: $white;

        &:hover {
            color: $logo-main;
            background-color: $white;
        }
    }

    &-viewer {
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: $dcm-viewer;
        font-family: $ff-normal;
        padding: 0 4px;
    }

    &-wide {
        padding-left: 40px;
        padding-right: 40px;
    }

    &-block {
        display: block;
        width: 100%;
    }

    &-disabled {
        background-color: $light-gray;
        border: 1px solid $medium-gray;
        color: $dark-gray;
        cursor: not-allowed;
        pointer-events: none;
    }

    &.with-dropdown {
        padding-right: 30px;

        .dropdown-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 28px;
            height: 100%;
            text-align: center;
            line-height: 42px;
            font-size: 24px;


            &::before {
                content: "";
                height: 70%;
                background-color: #FFFFFF;
                width: 1px;
                z-index: 99;
                top: 15%;
                position: absolute;
                left: 2px;
            }

            mat-icon {
                width: 20px;
            }
        }
    }
}

.app-ibutton-container {
    display: inline-flex;
    position: relative;
    height: 100%;

    .ibutton {
        border: none;
        background-color: unset;
        padding: 0;
        line-height: 100%;
        padding: 8px;

        &-label {
            font-size: 14px;
            display: block;
            line-height: 18px;
        }

        &.ibutton-transparent {
            background-color: transparent;
            color: $black;
            font-size: 22px;
            text-align: center;
        }

        &.ibutton-simple {
            background-color: $white;
            color: $black;
            font-size: 22px;
            text-align: center;

            &:hover {
                background-color: $logo-hover;
            }
        }

        &.ibutton-viewer {
            background-color: $black;
            color: $dcm-viewer;
            font-size: 28px;

            &.active {
                color: rgb(13, 235, 13);
            }
        }

        &.ibutton-disabled {
            color: $shadow-gray;
            pointer-events: none;
        }

        .dropdown-icon {
            width: 10px;
            display: inline-block;
            text-align: center;
            transition: transform .5s ease;
            transform-origin: right;
        }
    }

    .ibutton-dropdown-menu {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 999;
        text-align: right;
        min-width: 150px;
        border: 1px solid $shadow-gray;

        button {
            border: none;
            padding: 8px;
            background-color: $white;
            color: $black;
            font-family: $ff-semibold;
            display: block;
            width: 100%;
            font-size: 14px;
            border-bottom: 1px solid $shadow-gray;

            &:hover {
                background-color: $logo-hover;
            }

            &:last-of-type {
                border-bottom: none;
            }

            span {
                vertical-align: top;
                margin-top: 2px;
                font-size: 16px;
                margin-right: 4px;
            }

            &.button-disabled {
                background-color: $light-gray;
                color: $medium-gray;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }

    &:hover {
        .ibutton-dropdown-menu {
            display: block;
        }

        .dropdown-icon {
            transform: rotate(180deg);
        }
    }
}

.app-button-container {
    display: inline-block;
    position: relative;
    
    .dropdown-menu {
        position: absolute;
        top: 102%;
        right: 0;
        z-index: 999;
        text-align: right;
        min-width: 100px;
        border: 1px solid $logo-dark;
        border-radius: 4px;

        button {
            border: none;
            border-bottom: 1px solid $logo-dark;
            padding: 8px;
            background-color: $white;
            color: $logo-main;
            font-family: $ff-semibold;
            display: block;
            width: 100%;

            i {
                vertical-align: top;
                margin-top: 2px;
                font-size: 16px;
                margin-right: 4px;
            }

            &.button-disabled {
                background-color: $light-gray;
                color: $medium-gray;
                cursor: not-allowed;
                pointer-events: none;
            }

            &:first-of-type {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-of-type {
                border-bottom: none;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
    }
}

/**
 * Dropdown used for the menu
 */
//  .dropdown {
//     background-color: rgba(0, 0, 0, 0.8);
//     display: none;
//     left: -25px;
//     list-style: none;
//     opacity: 0;
//     overflow: hidden;
//     position: absolute;
//     visibility: hidden;
//     width: 200px;
//     z-index: 9;

//     li {
//         clear: both;
//         color: $text-menu;
//         font-weight: $font-normal;
//         width: 100%;
//         transition: all 0.5s ease;

//         &:hover {
//             padding-left: 12px;
//             background-color: rgba(0, 0, 0, 0.5);
//         }
//     }

//     &:hover {
//         @include dropdown-visible;
//     }

//     @media (max-width: $large-phone) {
//         left: 5%;
//         width: 90%;
//     }
// }

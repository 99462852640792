@import 'variables';
@import 'mixins';

// Some global styles and classes that should be applied across the website
/**
 * Default styles for the tags
 */
html,
body,
p {
    color: $text-default;
    font-family: "Open Sans Regular", Helvetica, sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
}

p {
    margin-bottom: 10px;
    line-height: 1.5;
}

ul,
li {
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

h1 {
    font-size: 48px;
    font-family: $ff-bold;
    line-height: 52px;
}

h2 {
    font-size: 32px;
    font-family: $ff-bold;
    line-height: 36px;
    margin-bottom: 24px;
}

h3 {
    font-size: 24px;
    line-height: 28px;
    font-family: $ff-bold;
}

h4 {
    font-size: 20px;
    line-height: 22px;
    font-family: $ff-semibold;
}

/**
 * Useful classes
 */
.inline-link {
    color: $logo-dark;
    font-family: $ff-semibold;
    cursor: pointer;
}

.simple-list {
    padding-left: 20px;
    margin-bottom: 16px;

    li {
        line-height: 1.5;
    }
}

.font-bold {
    font-weight: $font-bold;
}

.ellipsed {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ellipsed:hover {
    background: red;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.clearable-tag {
    padding: 4px 8px;
    border-radius: 30px;
    background-color: $sidebar-active;
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;

    span {
        vertical-align: top;
        display: inline-block;
        margin-top: 7px;
        margin-right: 8px;
    }

    mat-icon {
        cursor: pointer;
    }
}

.valign-wrapper {
    display: flex;
    align-items: center;

    &.justify-end {
        justify-content: flex-end;
    }

    &.justify-center {
        justify-content: center;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.error-message {
    color: $error;
    font-size: 12px;
}

/**
 * JSON EDITOR
 */

textarea.jsoneditor-text,
.ace_editor.ace-jsoneditor,
.jsoneditor-mode-tree,
.jsoneditor-mode-view {
    min-height: 60vh;
}
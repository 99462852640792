@import 'mixins';
@import 'variables';

// 
// THIS TAKES CARE OF THE LAYOUT, GRID, CONTAINER, ROWs, COLs,
// INCLUDING RESPONSIVE
//
.container {
    margin: 0 auto;
    position: relative;
    width: 1140px;

    &.narrow {
        width: 960px;

        @media (max-width: $tablet) {
            width: 90%;
        }
    }

    &.narrower {
        width: 800px;

        @media (max-width: $tablet) {
            width: 90%;
        }
    }

    @media (max-width: $tablet) {
        width: 90%;
    }

    &.minheight {
        min-height: $container-min-height;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;

    &.align-end {
        align-items: flex-end;
    }

    &.align-center {
        align-items: center;
    }

    @media(max-width: $large-phone) {
        &.mobile-reverse {
            flex-flow: column-reverse;
        }
    }
}

.col-3 {
    flex-basis: 25%;
    width: 25%;
}

.col-4 {
    flex-basis: 33.33%;
    width: 33.33%;
}

.col-6 {
    flex-basis: 50%;
    width: 50%;
}

.col-8 {
    flex-basis: 66.66%;
    width: 66.66%;
}

.col-12 {
    flex-basis: 100%;
    width: 100%;
}

.col-3, .col-4, .col-6, .col-8, .col-12 {
    box-sizing: border-box;
    padding: 12px;

    @media(max-width: $large-phone) {
        margin: 16px 0;
        flex-basis: 100%;
        width: 100%;
    }
}

.side-by-side-container {
    display: flex;
    justify-content: center;

    > .side {
        // flex: 1;
        // flex-grow: 1;
        // flex-shrink: 1;
        flex-basis: 55%;
    }

    > .one-third {
        width: 33.33%;
    }
}
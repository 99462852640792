@use '@angular/material' as mat;

@import 'https://fonts.googleapis.com/css?family=Noto+Sans&display=swap';
@import 'reset.scss';
@import 'global.scss';
@import 'forms.scss';
@import 'layout.scss';
@import 'tables.scss';
@import 'icons.scss';
@import 'ag-grid.scss';
// @import "jsoneditor/dist/jsoneditor.min.css";
@import 'monaco-editor/min/vs/editor/editor.main';


input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}


.no-margin-dialog {
  width: 50vw;
  height: 35vh;

  &.minimized {
    height: 26px;
  }

  .mat-dialog-container {
    padding: 0;
  }

  dcm-viewer {
    height: 100%;
    overflow: hidden;
  }
}

mat-progress-bar {

  .mat-progress-bar-buffer {
    background-color: $logo-light;
  }

  .mat-progress-bar-fill::after {
    background-color: $logo-dark;
  }
}





app-customer,
.study-charges-wrap,
.portal-report-table-toolbar,
.canned-text-wrap {
  @include mat.form-field-density(-2);
  @include mat.select-density(-2);
  @include mat.chips-density(-2);
  @include mat.datepicker-density(-2);
  @include mat.icon-density(-2);
  @include mat.input-density(-2);

}



// Fonts
@font-face {
    // src: url('../../ fonts/OpenSans-Regular.ttf');
    src: url('../../assets/fonts/OpenSans-Regular.ttf');
    font-family: "Open Sans Regular";
}
@font-face {
    src: url('../../assets/fonts/OpenSans-Bold.ttf');
    font-family: "Open Sans Bold";
}
@font-face {
    src: url('../../assets/fonts/OpenSans-SemiBold.ttf');
    font-family: "Open Sans SemiBold";
}
@font-face {
    src: url('../../assets/fonts/OpenSans-Light.ttf');
    font-family: "Open Sans Light";
}

// Colors
$error: #790000;
$warning: #b56d00;
$logo-main: #38C7F3;
$logo-dark: #22ABDF;
$logo-light: #92D7F4;
$logo-hover: #c6e5ef;
$accent: #026C94;
$dcm-viewer: #9CCEF9;
$success: #077A09;


// Borders
$black: #000000;
$light-gray: #EDEDED;
$shadow-gray: #CECECE;
$dark-gray: #2D2D2D;
$medium-gray: #717171;
$white: #FFFFFF;

// Text
$text-default: #000000;
$text-link: #2C92C1;
$text-menu: #C5C5C5;
$text-menu-active: #FFFFFF;
$text-menu-hover: #2AAFE7;
$text-footer-top: #A9A9A9;
$text-footer-bottom: #AAAAAA;
$sidebar-hover: #CC6600;

// Font
$font-bold: 700;
$font-normal: 400;
$font-light: 200;
$ff-normal: "Open Sans Regular";
$ff-bold: "Open Sans Bold";
$ff-light: "Open Sans Light";
$ff-semibold: "Open Sans SemiBold";

// Breakpoints
$tablet: 1024px;
$large-phone: 768px;
$small-phone: 515px;
$container-min-height: 700px;

/* OLD */
$main-text-color: #001460;
$main-color: #39C3EE;
$white-color: #fff;
$purple-color: #6984DD;
$white-10: #FAFBFC;
$white-15: #F8F9FB;
$gray-10: #CDCDCD;
$gray-20: #DADADA;
$gray-40: #F6F7F7;
$gray-30: #7F8FA4;
$border-date-table: #E6EAEE;
$tableHeaderBackground : rgb(218, 238, 245);
$barcolor: #7aa1a8;
$danger: #db6451;


// Dashboard color palette
$text-color: #333333;
$text-color-light: #666666;
$text-faded: #DEDEDE;
$sidebar-faded: #ECF9FD;
$sidebar-active: #39C3EE;
$content-background: #F0F2F5;
